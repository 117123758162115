/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";


@font-face {
    font-family: 'Lato';
    src: url('/assets/fonts/lato-normal.woff') format('woff')
}

* {
    font-family: 'Lato', sans-serif !important;
}

.center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.right-content {
    display: flex;
    justify-content: right;
    align-items: center;
}

.left-content {
    display: flex;
    justify-content: left;
    align-items: center;
}

.primary-color {
    color: var(--ion-color-primary);
}

.text-color {
    color: var(--text-color);
}

.center-text {
    text-align: center;
}

.padding-30 {
    padding: 30px;
}

.logo {
    font-size: 76px;
    display: block;
    left: 0;
    right: 0;
    margin: auto;
}

ion-button {
    font-weight: 700;
    // font-size: 18px;
    font-family: Calibri;
}

.flex-grow-1 {
    flex-grow: 1;
}

.height-100 {
    height: 100%;
}